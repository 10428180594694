<template>
  <component :is="component" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: 'AutomationInput',
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    component() {
      const type = this.type.charAt(0).toUpperCase() + this.type.slice(1).toLowerCase() + 'Input';
      return () => import(`./components/${type}`);
    },
  },
};
</script>

<template>
  <b-modal
    :id="modalId"
    :title="
      $t(
        'sparkmembers.contents.views.editor.components.ebook.components.automations.list.components.details-editor-modal.title'
      )
    "
    size="lg"
    centered
    hide-footer
  >
    <AutomationInput
      v-if="Object.keys(automation).length"
      :type="getAutomation(automation.integration)"
      :automation="automation.settings"
      @save="updateAutomation"
      @cancel="$bvModal.hide(modalId)"
    />
  </b-modal>
</template>
<script>
import AutomationInput from '@/components/AutomationInput';

export default {
  name: 'DetailsEditorModal',
  props: {
    automation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getAutomation(type) {
      const types = ['Sendgrid', 'Messages', 'Student::Categorization', 'Webhook', 'HeroWebhook'];
      const variants = ['email', 'message', 'tag', 'webhook', 'webhook'];
      return variants[types.indexOf(type)];
    },
    updateAutomation(attrs) {
      this.$emit('change', { ...this.automation, ...attrs });
    },
  },
  components: {
    AutomationInput,
  },
  computed: {
    modalId() {
      return 'details-editor-modal';
    },
  },
};
</script>
